import Head from 'next/head';
import * as React from 'react';
import styles from '../styles/Home.module.css';

const Index = () => {
  const [isClient, setIsClient] = React.useState(false);

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  return (
    <>
      <Head>
        <title>AltHire AI</title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#4455ff" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <main className={styles.main} data-lk-theme="default">
        <div className="header" style={{
          textAlign: 'center',
        }}>
          <h1>AltHire AI</h1>
          <h2 style={{
            paddingBlock: '12px',
          }}>
            Experience the Future of Hiring with AI-Powered Interviews
          </h2>
          <a style={{
            padding: '4px 8px',
            background: '#4455ff',
            marginTop: 2,
            borderRadius: 4,
            textDecoration: 'none',
            color: '#fff',
            fontSize: 16,
          }} href={'https://althireai.com'}>Get Started</a>
        </div>

      </main>
      <footer data-lk-theme="default">
        © 2024-2025 AltHire AI. All Rights Reserved
      </footer>
    </>
  );
};

export default Index;